import { format } from 'date-fns'

const LAST_EVENT_DAY = '2023/1/2'

/**
 * 日付を同じフォーマットに変換します。
 * @param {Date} date フォーマットするDateインスタンス
 */
const baseFormat = (date) => {
  return format(date, 'yyyy/MM/dd')
}

/**
 * 今日がイベント最終日かを真偽値で返します。
 */
const isLastDay = () => {
  const today = new Date()
  const lastDay = new Date(LAST_EVENT_DAY)
  return baseFormat(today) === baseFormat(lastDay)
}

export { isLastDay }
