/**
 * おみくじの結果を返します。
 * `userId`指定なしでランダムな取得、指定ありでそのユーザーの結果を返します。
 * @param {String} userId LINEのユーザーID
 */
const getOmikuji = async (userId) => {
  if (process.env.MODE === 'development') {
    // mock
    console.log('おみくじ結果 モック取得')
    return {
      status: false,
      result: {
        fortune: '大吉',
      },
    }
  } else {
    console.log('おみくじ結果の取得開始')
    const response = await fetch(`${process.env.API_BASE_URL}/drawOmikuji`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-api-key': process.env.API_BASE_KEY,
      },
      body: JSON.stringify({
        userId: userId,
      }),
    })
    if (response.status !== 200) {
      console.log(response.status)
      alert('おみくじが利用できません(2)' + response.statusText)
    }
    const omikuji = await response.json()
    console.log('↓おみくじ結果の取得完了')
    console.table(omikuji)

    return omikuji
  }
}

export { getOmikuji }
