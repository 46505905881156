/**
 * おみくじ履歴を返します。
 * @param {String} userId LINEのユーザーID
 */
const getOmikujiHistory = async (userId) => {
  console.log('↓userId')
  console.log(userId)
  if (process.env.MODE === 'development') {
    // mock
    console.log('おみくじ履歴 モック取得')
    return {
      canOmikuji: false,
      result: {
        fortune: `末吉`,
      },
    }
  } else {
    console.log('おみくじ履歴の取得開始')
    const response = await fetch(`${process.env.API_BASE_URL}/todayOmikuji`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-api-key': process.env.API_BASE_KEY,
      },
      body: JSON.stringify({
        userId: userId,
      }),
    })
    if (response.status !== 200) {
      console.log(response.status)
      alert('おみくじが利用できません。(1)' + response.statusText)
    }
    const history = await response.json()
    console.log('↓おみくじ履歴の取得終了')
    console.table(history)

    return history
  }
}

export { getOmikujiHistory }
