/**
 * 要素を表示します。
 * @param {HTMLElement} el 表示する要素
 */
const show = (el) => {
  el.setAttribute('aria-hidden', 'false')
}

/**
 * 要素を非表示にします。
 * @param {HTMLElement} el 非表示にする要素
 */
const hide = (el) => {
  el.setAttribute('aria-hidden', 'true')
}

/**
 * bodyタグに出し分けするクラス名
 */
const area = {
  start: '-start',
  video: '-video',
  apply: '-apply',
}

const body = document.getElementsByTagName('body')[0]

/**
 * 受け取ったエリア名だけを表示します。
 * @param {String} areaName 表示するエリア名（`area`）
 */
const switchClassName = (areaName) => {
  body.classList.remove(area.start)
  body.classList.remove(area.video)
  body.classList.remove(area.apply)
  body.classList.add(areaName)
}

/**
 * 初期画面だけを表示する。
 */
const showStartArea = () => {
  const target = area.start
  switchClassName(target)
}

/**
 * 動画画面だけを表示する。
 */
const showVideoArea = () => {
  const target = area.video
  switchClassName(target)
}

/**
 * 結果画面だけを表示する。
 */
const showResultArea = () => {
  const target = area.apply
  switchClassName(target)
}

export { show, hide, showStartArea, showVideoArea, showResultArea }
